import type { Tab } from '@/types'
import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { uniqBy } from 'lodash-es'
import { getSessionStorage, setSessionStorage } from 'peace-library'

export const useTabsStore = defineStore(
  'tabs',
  () => {
    const tabs = ref<Tab[]>([])
    const tabsHistory = ref<Tab[]>([])
    const activeTab = ref('')

    const keepAliveTabs = computed(() => {
      return tabs.value?.filter((tab) => tab.componentName && tab.cache == true).map((tab) => tab.componentName)
    })

    const addTab = (meta: any) => {
      const currentTabIndex = tabs.value.findIndex((tab) => tab.componentName === meta.componentName)
      const currentTabHistoryIndex = tabsHistory.value.findIndex((tab) => tab.componentName === meta.componentName)

      if (currentTabIndex === -1) {
        tabsHistory.value.push(meta)
        tabs.value.push(meta)
      } else {
        tabsHistory.value.splice(currentTabHistoryIndex, 1)
        tabsHistory.value.push(meta)
        tabs.value[currentTabIndex] = meta
      }
      tabsHistory.value = uniqBy(tabsHistory.value, (item) => item.componentName)
    }

    const setTab = (componentName: string) => {
      activeTab.value = componentName

      const currentTabHistory = tabsHistory.value.find((tab) => tab.componentName === componentName)
      const currentTabHistoryIndex = tabsHistory.value.findIndex((tab) => tab.componentName === componentName)

      tabsHistory.value.splice(currentTabHistoryIndex, 1)

      if (currentTabHistory) {
        tabsHistory.value.push(currentTabHistory)
      }
    }

    const setLastTab = () => {
      const lastTabComponentName = tabsHistory.value[tabsHistory.value.length - 1].componentName

      setTab(lastTabComponentName)
    }

    const removeTab = (meta: any) => {
      const historyIndex = tabsHistory.value.findIndex((tab) => tab.componentName === meta.componentName)
      const index = tabs.value.findIndex((tab) => tab.componentName === meta.componentName)
      tabs.value.splice(index, 1)
      tabsHistory.value.splice(historyIndex, 1)
    }

    const removeLeftTab = () => {
      const index = tabs.value.findIndex((tab) => tab.componentName === activeTab.value)

      for (let i = 0; i < index; i++) {
        const historyIndex = tabsHistory.value.findIndex((tab) => tab.componentName === tabs.value[i].componentName)
        tabsHistory.value.splice(historyIndex, 1)
      }

      tabs.value.splice(0, index)
    }

    const removeRightTab = () => {
      const index = tabs.value.findIndex((tab) => tab.componentName === activeTab.value)

      for (let i = index + 1; i < tabs.value.length; i++) {
        const historyIndex = tabsHistory.value.findIndex((tab) => tab.componentName === tabs.value[i].componentName)
        tabsHistory.value.splice(historyIndex, 1)
      }

      tabs.value.splice(index + 1, tabs.value.length)
    }

    const removeOthersTab = () => {
      removeLeftTab()
      removeRightTab()
    }

    //移除所有tab
    const removeAllTabs = () => {
      tabs.value = []
    }

    return {
      tabs,
      tabsHistory,
      activeTab,
      keepAliveTabs,
      addTab,
      setTab,
      setLastTab,
      removeTab,
      removeLeftTab,
      removeRightTab,
      removeOthersTab,
      removeAllTabs
    }
  },
  {
    persist: {
      storage: {
        getItem: getSessionStorage,
        setItem: setSessionStorage
      }
    }
  }
)
