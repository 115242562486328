import { reactive, ref } from 'vue'
import type { Ref } from 'vue'
import dayjs from 'dayjs'
import { dynamicDays, currentDynamicMonths, currentYear, getMonth, isBeforeDates, spaceMonths } from 'peace-library'

const previousMonthDate = dayjs().subtract(1, 'month').format('YYYY-MM')

const useGetTime = ({ dateType, yearEve = false, isNaturalYear = false, defaultmonth, previousMonth = false }: any) => {
  const dates = currentDynamicMonths(2)
  const day = dynamicDays()
  const defaultDateRanges = defaultmonth ? currentDynamicMonths(defaultmonth) : currentDynamicMonths()
  const defaultYear = currentYear()
  const startTime: Ref<string | null> = ref(null)
  const endTime: Ref<string | null> = ref(null)
  if (dateType === '1') {
    startTime.value = defaultYear
    endTime.value = defaultYear
  }
  if (dateType === '2') {
    if (yearEve) {
      const nowMonth = getMonth(new Date())
      if (nowMonth === '01' || nowMonth === '02') {
        startTime.value = currentYear() + '-' + '01'
        endTime.value = defaultDateRanges[1]
      } else {
        startTime.value = defaultDateRanges[0]
        endTime.value = defaultDateRanges[1]
      }
    } else {
      if (previousMonth) {
        startTime.value = previousMonthDate
        endTime.value = previousMonthDate
      } else {
        startTime.value = defaultDateRanges[0]
        endTime.value = defaultDateRanges[1]
      }
    }
    // startTime.value = dates[0]
    // endTime.value = dates[1]
    if (isNaturalYear) {
      const nowtTime = currentYear() + '-' + '01'
      startTime.value = nowtTime
      endTime.value = defaultDateRanges[1]
    }
  }
  if (dateType === '3') {
    startTime.value = day[0]
    endTime.value = day[1]
  }
  return {
    startTime,
    endTime
  }
}

//isNaturalYear:是否从自然年开始 默认false    defaultmonth:默认月份 一般都是本月往前推三个月  有些比较特殊   yearEve 是否需要跨年查询 true:不允许跨年查询 默认false
export const useDateTime = ({ dateType, yearEve = false, isNaturalYear = false, defaultmonth, previousMonth = false }: any) => {
  const { startTime, endTime } = useGetTime({ dateType, yearEve, isNaturalYear, defaultmonth, previousMonth })
  return reactive({
    startTime,
    endTime
  })
}
