import type { ExtractPublicPropTypes, MaybeRef, PropType, Ref } from 'vue'
import type { StrictUseAxiosReturn } from '@vueuse/integrations'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import type { DataTableColumn } from 'naive-ui'
import { dataTableProps } from 'naive-ui'

export type Size = 'small' | 'medium' | 'large'
export type Request = StrictUseAxiosReturn<any, AxiosResponse<any, any>, any> & Promise<StrictUseAxiosReturn<any, AxiosResponse<any, any>, any>>
export type Config = MaybeRef<AxiosRequestConfig>
export type OnChangeSize = (size: Size) => void
export type OnChangeColumns = (columns: DataTableColumn[]) => void
export type OnLoad = (config?: Config) => Promise<StrictUseAxiosReturn<any, AxiosResponse<any, any>, any>>
export type OnReload = () => Promise<StrictUseAxiosReturn<any, AxiosResponse<any, any>, any>>
export type OnReset = () => Promise<StrictUseAxiosReturn<any, AxiosResponse<any, any>, any>>
export type OnImport = () => void
export type OnExport = () => void
export type DataTableProParams = {
  request: Request
  config: Config
  columns: Ref<DataTableColumn[]>
  /** 立即执行 */
  immediate?: Boolean
  /** 显示分页 */
  showPagination?: Boolean
  /** 显示行号 */
  showLineNumber?: Boolean
}

const dataTableProProps = {
  /** 源生属性 */
  ...dataTableProps,

  fingerprint: { type: String as PropType<String>, required: true },

  config: { type: Object as PropType<Config>, required: true },
  rawConfig: { type: Object as PropType<Config>, required: true },
  previousConfig: { type: Object as PropType<Config>, required: true },

  onChangeSize: { type: Function as PropType<OnChangeSize>, required: true },
  onChangeColumns: { type: Function as PropType<OnChangeColumns>, required: true },
  onLoad: { type: Function as PropType<OnLoad>, required: true },
  onReload: { type: Function as PropType<OnReload>, required: true },
  onReset: { type: Function as PropType<OnReset>, required: true },
  onImport: { type: Function as PropType<OnImport> },
  onExport: { type: Function as PropType<OnExport> }
}

export type DataTableProps = ExtractPublicPropTypes<typeof dataTableProps>
export type DataTableProProps = ExtractPublicPropTypes<typeof dataTableProProps>

export { dataTableProps, dataTableProProps }
