/**
 * Boot files are your "main.ts"
 **/

import createConfiguration from './boot/create-configuration'
import createApp from './boot/create-app'

import bootLibrary from './boot/boot-library'
import bootComponents from './boot/boot-components'
import bootStyle from './boot/boot-style'

const install = async () => {
  // TODO:
  // 最优：从服务端获取用户配置，传递给 bootstrap 文件，根据配置信息按条件加载
  // 次要：从本地获取配置文件，但无法多端同步与更新
  const configuration = await createConfiguration()

  // 初始化 library
  await bootLibrary({ configuration })
  await bootStyle({ configuration })

  // 初始化 app
  const { app, router, store } = await createApp()

  // 初始化 components
  await bootComponents({ app, router, store, configuration })

  // 挂载到 dom
  app.mount('#app')
}

install()
