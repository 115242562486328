<template>
  <ApplicationProvider>
    <RouterView></RouterView>
  </ApplicationProvider>
</template>

<script lang="ts" setup>
import { system } from '@/config'
import { useTitle } from '@vueuse/core'
import { RouterView } from 'vue-router'
import { useUserStore, useThemeStore } from '@/store'

const user = useUserStore()
const theme = useThemeStore()
const title = useTitle()

user.startup()
theme.startup()
title.value = system.title
</script>
