<template>
  <div v-if="!loginState" class="w-screen h-screen">
    <div class="w-screen h-screen grid lg:grid-cols-12 bg-slate-100/50 dark:bg-slate-800/80">
      <div class="absolute z-10 left-4 top-4" v-if="showLogo">
        <IconSvgFusionLogoFull class="w-80 h-12 my-4"></IconSvgFusionLogoFull>
      </div>
      <div class="h-full hidden lg:block lg:col-span-8 relative">
        <div class="h-full flex justify-center items-center">
          <IconSvgLoginBg class="h-3/4 w-full max-w-3xl text-violet-500"></IconSvgLoginBg>
        </div>
      </div>
      <div class="h-full flex flex-col col-span-12 lg:col-span-4 justify-center bg-white dark:bg-slate-700/90 pb-8">
        <div class="w-full flex justify-center items-center px-16 sm:px-32 md:px-48 lg:px-16 xl:px-20 mx-auto mb-4">
          <component :is="signComponent"></component>
        </div>
        <div class="w-full flex justify-end items-center px-16 sm:px-32 md:px-48 lg:px-16 xl:px-20 mx-auto">
          <NButton text type="primary" v-if="signType === 'smsCode'" @click="signType = 'password'">密码登录</NButton>
          <NButton text type="primary" v-if="signType === 'password'" @click="signType = 'smsCode'">验证码登录</NButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { isLogin, removeUserInfo } from '@/utils'
import SignByPassword from './components/SignByPassword.vue'
import SignBySMSCode from './components/SignBySMSCode.vue'

defineOptions({
  name: 'SystemLogin'
})

const route = useRoute()
const router = useRouter()
const loginState = ref(!!isLogin())
const showLogo = ref(window.configuration.showLogo === 'true')

const signType = ref<'smsCode' | 'password'>('password')
const signComponent = computed(() => {
  if (signType.value === 'password') {
    return SignByPassword
  } else if (signType.value === 'smsCode') {
    return SignBySMSCode
  }
})

if (route.query.expired === '1') {
  removeUserInfo()
}

if (loginState.value) {
  router.push({ name: (route.query.referrer as string) || '/' })
}
</script>

<style scoped>
.p-layout-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 32px;
  z-index: 999;
  background: none;
}
</style>
