import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

export const useDayjs = () => {
  // extend update locale
  dayjs.extend(updateLocale)

  // set week start
  dayjs.updateLocale('en', {
    weekStart: 1
  })
}
