import type { RouteRecordRaw } from 'vue-router'
import { ExceptionNotFound } from 'peace-components'
import { isLogin } from '@/utils'
import SystemPortal from '@/views/system/system-portal/index'
import SystemLogin from '@/views/system/system-login/index'

export const constantRoutes: Array<RouteRecordRaw> = [
  {
    name: '/',
    path: '/',
    redirect: () => {
      if (isLogin()) {
        return { path: '/home' }
      } else {
        return { path: '/login' }
      }
    }
  },
  {
    name: 'home',
    path: '/home',
    component: SystemPortal
  },
  {
    name: 'login',
    path: '/login',
    component: SystemLogin
  },
  {
    name: 'ErrorPage',
    path: '/:path(.*)*',
    component: ExceptionNotFound
  }
]
