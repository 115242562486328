<template>
  <NSpin class="h-screen w-screen" :description="loading.description" :show="loading.show">
    <NConfigProvider
      class="h-screen w-screen overflow-hidden"
      inline-theme-disabled
      :theme="theme.naiveTheme"
      :theme-overrides="theme.naiveThemeOverrides"
      :locale="zhCN"
      :component-options="componentOptions"
      :date-locale="dateZhCN"
    >
      <NLoadingBarProvider>
        <NDialogProvider>
          <NNotificationProvider :placement="`top-right`">
            <NMessageProvider>
              <ApplicationGlobalProvider>
                <slot></slot>
              </ApplicationGlobalProvider>
            </NMessageProvider>
          </NNotificationProvider>
        </NDialogProvider>
      </NLoadingBarProvider>
    </NConfigProvider>
  </NSpin>
</template>

<script lang="tsx" setup>
import type { GlobalComponentConfig } from 'naive-ui'
import { ref } from 'vue'
import { zhCN, dateZhCN } from 'naive-ui'
import { CloudOutlined } from '@vicons/antd'
import { useThemeStore, useLoadingStore } from '@/store'

const theme = useThemeStore()
const loading = useLoadingStore()
const componentOptions = ref<GlobalComponentConfig | undefined>({
  Empty: {
    renderIcon: () => {
      return <CloudOutlined></CloudOutlined>
    }
  }
})
</script>
