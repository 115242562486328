<template>
  <NSpace class="w-full" vertical>
    <div class="mb-4 w-full">
      <NText class="block text-2xl mb-4">{{ system.title }}</NText>
      <NText depth="3">{{ system.subtitle }}</NText>
    </div>

    <NForm ref="formRef" size="large" label-placement="top" label-width="auto" :model="model" :rules="rules" @keyup.enter="handleLogin">
      <NFormItem path="username">
        <template #label>
          <NText depth="3">手机号</NText>
        </template>
        <NInput v-model:value="model.username" placeholder="Enter phone number">
          <template #suffix>
            <NIcon :component="UserOutlined" />
          </template>
        </NInput>
      </NFormItem>
      <NFormItem path="code">
        <template #label>
          <NText depth="3">验证码</NText>
        </template>
        <NInput v-model:value="model.code" placeholder="Enter SMS code">
          <template #suffix>
            <NButton text :disabled="isActive" @click="handleSendCode">获取验证码 {{ isActive ? time : '' }}</NButton>
          </template>
        </NInput>
      </NFormItem>
      <NFormItem path="rememberMe" :show-label="false">
        <NCheckbox v-model:checked="model.rememberMe">记住我</NCheckbox>
      </NFormItem>
      <NFormItem :show-label="false">
        <NButton type="primary" class="!w-full !shadow-xl !rounded-md" @click="handleLogin"> 登录 </NButton>
      </NFormItem>
    </NForm>
  </NSpace>
</template>

<script setup lang="ts">
import type { FormInst } from 'naive-ui'
import { ref } from 'vue'
import { UserOutlined } from '@vicons/antd'
import { cloneDeep } from 'lodash-es'
import { encrypt, getSessionStorage, setSessionStorage, useAxios } from 'peace-library'
import { setRememberMe, setUserInfo } from '@/utils'
import { system } from '@/config'
import { login, sendCode } from '../service'
import { useIntervalFn } from '@vueuse/core'
import dayjs from 'dayjs'

const countdownTime = ref(60)
const time = ref(countdownTime.value)

const { pause, resume, isActive } = useIntervalFn(
  () => {
    time.value--
    time.value <= 0 && pause()
  },
  1000,
  { immediate: false }
)

const formRef = ref<FormInst | null>(null)

const model = ref({
  username: '',
  password: '',
  rememberMe: true,
  code: '',
  uuid: '',
  grantType: 'code'
})

const rules = {
  username: { required: true, message: '请输入手机号', trigger: 'blur' },
  code: { required: true, message: '请输入验证码', trigger: 'blur' }
}

const smsCodeDate = getSessionStorage('SMSCode')
if (smsCodeDate && dayjs(new Date()).diff(dayjs(smsCodeDate), 'second') < countdownTime.value) {
  // 重置倒计时
  time.value = countdownTime.value - dayjs(new Date()).diff(dayjs(smsCodeDate), 'second')
  // 启动定时器
  resume()
}

const handleSendCode = async () => {
  await sendCode().execute({ params: model.value })
  // 存储当次状态
  setSessionStorage('SMSCode', new Date())
  // 重置倒计时
  time.value = countdownTime.value
  // 启动定时器
  resume()
}

const handleRememberMe = () => {
  if (model.value.rememberMe) {
    setRememberMe()
  }
}

const handleSign = async () => {
  await login()
    .execute({ data: model.value })
    .then((response) => {
      if (response.data.value?.data) {
        setUserInfo(response.data.value?.data)
        useAxios(response.data.value?.data.token, response.data.value?.data.user.user.tenantId)
      }
    })
}

const handleLogin = async (e: MouseEvent | KeyboardEvent) => {
  e.preventDefault()

  const validateError = await formRef.value?.validate()
  if (validateError) {
    return
  }

  await handleRememberMe()
  await handleSign()

  window.location.href = window.location.origin + window.location.pathname
}
</script>
