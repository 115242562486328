import { defineStore } from 'pinia'
import { naiveUIThemeOverrides, ui } from '@/config'
import { computed, ref } from 'vue'
import { darkTheme } from 'naive-ui'

export const useThemeStore = defineStore('theme', () => {
  const theme = ref(ui.theme)

  const isLightTheme = computed(() => theme.value === 'light')
  const isDarkTheme = computed(() => theme.value === 'dark')
  const naiveTheme = computed(() => (theme.value === 'dark' ? darkTheme : undefined))
  const naiveThemeOverrides = computed(() => naiveUIThemeOverrides)
  const eChartsTheme = computed(() => (theme.value === 'dark' ? 'dark' : 'light'))

  const setTheme = () => {
    // 添加 dark class，以支持 tailwind 深色主题
    // https://tailwindcss.com/docs/dark-mode
    if (isLightTheme.value) {
      document.documentElement.classList.add('light')
      document.documentElement.classList.remove('dark')
    } else {
      document.documentElement.classList.add('dark')
      document.documentElement.classList.remove('light')
    }
  }

  const toggleTheme = () => {
    theme.value = isLightTheme.value ? 'dark' : 'light'

    setTheme()
  }

  const startup = () => {
    setTheme()
  }

  return {
    theme,
    isLightTheme,
    isDarkTheme,
    naiveTheme,
    naiveThemeOverrides,
    eChartsTheme,

    startup,
    setTheme,
    toggleTheme
  }
})
