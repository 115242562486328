import type { AxiosResponse } from 'axios'
import { useAxiosDownload } from './use-axios-download'
import axios from 'axios'

export interface HttpResponse<T = any> {
  code?: number
  status?: number
  message: string
  data: T
  [key: string]: any
}

// FIXME:
// 初始化时，dom 未挂载，无法使用 naive ui 的 message
// 1. 优化应用生命周期，优先挂载 dom
const handleMessage = (message: string) => {
  if (window.$message) {
    window.$message?.info(message)
  } else {
    window.alert(message)
  }
}

/**
 * @description: axios hooks
 * @param {string} token token，用于鉴权
 * @param {string} tenant_id tenant_id，用于租户
 * @return {void}
 */
export const useAxios = (token?: string, tenant_id?: any): void => {
  // set authorization
  axios.defaults.headers.common['Authorization'] = token ?? undefined
  axios.defaults.headers.common['tenant_id'] = tenant_id ?? undefined

  // set timeout
  axios.defaults.timeout = 1000 * 600

  // set request interceptors
  axios.interceptors.request.use(
    (config) => {
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  // set response interceptors
  axios.interceptors.response.use(
    (response: AxiosResponse<HttpResponse<any>>) => {
      // 二进制下载请求，由 axios download 接管
      if (response.request.responseType === 'blob') {
        useAxiosDownload(response)

        return response
      }

      // 服务端统一数据结构
      if ((!response.data.status || !response.data.code) && !response.data.message && !response.data.data) {
        response.data = {
          status: 200,
          message: '',
          data: response.data
        }
      }

      // 业务正常
      if (response.data.status === 200 || response.data.code === 200) {
        return response
      }
      if (response.data.status === 1011) {
        return Promise.reject(response)
      }
      if (response.data.status === 401) {
        handleMessage('登录凭证已失效，为了你的信息安全，将跳转至登录页面')
        localStorage.clear()
        sessionStorage.clear()
        window.location.href = window.location.origin + window.location.pathname
        return Promise.reject(response)
      }
      handleMessage(response?.data?.message ?? '系统异常，请稍后重试！')
      return Promise.reject(response)
    },
    (error) => {
      // 请求被取消
      if (axios.isCancel(error)) {
        return Promise.reject(error)
      }

      // 请求错误
      if (error.status === 'ECONNABORTED' || error.code === 'ECONNABORTED' || error.message === 'Network Error') {
        handleMessage(error.message ?? '你的请求超时，请检查网络连接是否通畅')
        return Promise.reject(error)
      }

      // 请求状态不符合预期
      if (error.response) {
        switch (error.response.status || error.response.code || error.reponse.data.status) {
          case 400:
            handleMessage(error.response?.data?.message ? error.response?.data?.message : '系统异常，请稍后重试！')
            return Promise.reject(error)
          case 401:
            handleMessage('登录凭证已失效，为了你的信息安全，将跳转至登录页面')
            localStorage.clear()
            sessionStorage.clear()
            window.location.href = window.location.origin + window.location.pathname
            return Promise.reject(error)
          case 404:
            handleMessage('你请求的资源不存在')
            return Promise.reject(error)
          case 405:
            handleMessage('你请求的方法类型错误')
            return Promise.reject(error)
          case 500:
            handleMessage('内部服务器错误')
            return Promise.reject(error)
          case 417:
            handleMessage('请求出现异常')
            return Promise.reject(error)
        }
      }

      handleMessage('未知错误')
      return Promise.reject(error)
    }
  )
}
