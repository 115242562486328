import { createRouter as createRouterInstance, createWebHashHistory, type Router } from 'vue-router'
import { constantRoutes } from './create-constant-router'

const scrollBehavior: any = {}

export const createRouterHooks = (router: Router) => {
  router.beforeEach((to, from) => {
    const scrollElement = document.querySelector('.peace-layout__main .content')
    const fromName = from.name as string
    const toName = to.name as string
    const cache = to.meta?.cache as boolean

    if (scrollElement && cache) {
      // 记录来源页面的滚动位置
      scrollBehavior[fromName] = {
        left: scrollElement?.scrollLeft,
        top: scrollElement?.scrollTop
      }

      // FIXME:
      // vue router 全局守卫，暂时无法获取路由是否被加载，因为使用延时任务来模拟实现。
      // 还原目标页面的滚动位置
      setTimeout(() => {
        scrollElement.scrollLeft = scrollBehavior[toName]?.left ?? 0
        scrollElement.scrollTop = scrollBehavior[toName]?.top ?? 0
      }, 0)
    }
  })

  return router
}

export const createRouter = () => {
  return createRouterInstance({
    history: createWebHashHistory(),
    routes: constantRoutes
  })
}
