import { getDictionary } from './dictionary'

export type KV = {
  label: any
  value: any
  [key: string]: any
}

/**
 * 签名
 */
export const sign: (key: string) => string = (key) => {
  return `[${window?.configuration?.appName}]-[${window?.configuration?.appVersion}]:${key}`
}

/**
 *
 */
/**
 * @description: 格式化 source & value => Label
 * @example formatSV([{ label: "男", value: "1" }], "1") => 男
 * @param {*} s - 数据源
 * @param {*} v - 数据源的 value
 * @param {*} d - 无法 sv 映射时的默认值
 * @return {*}
 */
export const formatSV: (s: Array<KV>, v: string, d?: any) => string | undefined = (s, v, d = undefined) => {
  return s.find((item) => item.value == v)?.label ?? d
}

/**
 *
 * @description: 格式化 key & value => Label
 * @example formatKV("RC001", "1") => 男
 * @param {string} k - 字典 key
 * @param {string} v - 字典 key 的 value
 * @param {any} d - 无法 kv 映射时的默认值
 * @return {*}
 */
export const formatKV: (k: string, v: string, d?: any) => string | undefined = (k, v, d = undefined) => {
  const kList = (getDictionary(k) ?? []) as Array<KV>

  if (kList.length === 0) {
    //console.error(`[formatKV]缓存不存在"${k}"`)

    return undefined
  } else {
    return formatSV(kList, v, d)
  }
}

/**
 * 格式化数据字典
 * @param dictType
 * @param valueName
 * @returns
 */
export const formatDict = (dictType: string, valueName: string, value: any) => {
  if (!value) {
    return ''
  }
  const datas = getDictionary(dictType) || []
  return datas
    .filter((k: any) => k.value == value)
    .map((k: any) => k[valueName])
    .join('')
}
