import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createRouter, createRouterHooks } from '@/router'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import App from '@/App.vue'

export default async function () {
  const app = createApp(App)
  const router = createRouterHooks(createRouter())
  const store = createPinia()

  store.use(piniaPluginPersistedstate)
  app.use(store)
  app.use(router)

  return { app, router, store }
}
