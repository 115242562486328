<template>
  <NSpace class="w-full" vertical>
    <div class="mb-4 w-full">
      <NText class="block text-2xl mb-4">{{ system.title }}</NText>
      <NText depth="3">{{ system.subtitle }}</NText>
    </div>

    <NForm ref="formRef" size="large" label-placement="top" label-width="auto" :model="model" :rules="rules" @keyup.enter="handleLogin">
      <NFormItem path="username">
        <template #label>
          <NText depth="3">用户名</NText>
        </template>
        <NInput v-model:value="model.username" placeholder="Enter account">
          <template #suffix>
            <NIcon :component="UserOutlined" />
          </template>
        </NInput>
      </NFormItem>
      <NFormItem path="password">
        <template #label>
          <NText depth="3">密码</NText>
        </template>
        <NInput autofocus v-model:value="model.password" placeholder="Enter password" type="password">
          <template #suffix>
            <NIcon :component="LockOutlined" />
          </template>
        </NInput>
      </NFormItem>
      <NFormItem path="rememberMe" :show-label="false">
        <NCheckbox v-model:checked="model.rememberMe">记住我</NCheckbox>
      </NFormItem>
      <NFormItem :show-label="false">
        <NButton type="primary" class="!w-full !shadow-xl !rounded-md" @click="handleLogin"> 登录 </NButton>
      </NFormItem>
    </NForm>
  </NSpace>
</template>

<script setup lang="ts">
import type { FormInst } from 'naive-ui'
import { ref } from 'vue'
import { UserOutlined, LockOutlined } from '@vicons/antd'
import { cloneDeep } from 'lodash-es'
import { encrypt, useAxios } from 'peace-library'
import { setRememberMe, setUserInfo } from '@/utils'
import { system } from '@/config'
import { login } from '../service'

const formRef = ref<FormInst | null>(null)

const model = ref({
  username: 'admin',
  password: '',
  rememberMe: true,
  code: '',
  uuid: '',
  grantType: 'password'
})

const rules = {
  username: { required: true, message: '请输入用户名', trigger: 'blur' },
  password: { required: true, message: '请输入密码', trigger: 'blur' }
}

const handleRememberMe = () => {
  if (model.value.rememberMe) {
    setRememberMe()
  }
}

const handleSign = async () => {
  const params = cloneDeep(model.value)
  params.password = encrypt(params.password)

  const response = await login().execute({ data: params })
  if (response.data.value?.data) {
    setUserInfo(response.data.value?.data)
    useAxios(response.data.value?.data.token, response.data.value?.data.user.user.tenantId)
  }
}

const handleLogin = async (e: MouseEvent | KeyboardEvent) => {
  e.preventDefault()

  const validateError = await formRef.value?.validate()
  if (validateError) {
    return
  }

  await handleRememberMe()
  await handleSign()

  window.location.href = window.location.origin + window.location.pathname
}
</script>
