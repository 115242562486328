<template>
  <div class="overflow-auto h-screen">
    <div class="relative text-white" style="background: linear-gradient(90deg, #d53369 0%, #daae51 100%)">
      <!-- Header -->
      <div class="w-10/12 mx-auto mb-12">
        <div class="flex items-center py-4">
          <div class="flex items-center">
            <IconSvgFusionLogoOnlyEng width="240px" height="72px" class="mr-4"></IconSvgFusionLogoOnlyEng>
            <div class="text-3xl mr-4">医院运营管理平台</div>
            <div class="text-xl rounded bg-white bg-opacity-20 px-4 py-1 scale-75 mt-2">v{{ version }}</div>
          </div>

          <div class="flex-auto"></div>

          <LayoutHeader></LayoutHeader>
        </div>

        <hr class="border-b border-gray-100 opacity-25 my-0 py-0" />
      </div>

      <!-- Content -->
      <div class="w-10/12 mx-auto">
        <div v-for="system in systems" :key="system.title" class="rounded-2xl bg-gray-100/20 p-8 mb-8">
          <div class="text-2xl">{{ system.title }}</div>
          <div class="flex flex-wrap gap-x-4 gap-y-2 mt-2 text-base">
            <div
              v-for="sys in system.systems"
              :key="sys.title"
              @click="redirectToSystem(sys)"
              class="text-white text-opacity-90 cursor-pointer hover:font-bold"
            >
              {{ sys.title }}
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <svg viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <path
              d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
              opacity="0.100000001"
            ></path>
            <path
              d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
              opacity="0.100000001"
            ></path>
            <path
              d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
              id="Path-4"
              opacity="0.200000003"
            ></path>
          </g>
          <g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <path
              d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script setup lang="tsx">
import { version } from './../../../../../package.json'
import LayoutHeader from '@/layout/src/components/LayoutHeader.vue'

defineOptions({
  name: 'SystemPortal'
})

const systems = [
  {
    title: '基础支撑平台',
    systems: [
      { title: '管理平台', url: import.meta.env.VITE_APP_ADMIN_URL },
      { title: '天机平台', url: import.meta.env.VITE_APP_TJ_URL }
    ]
  },
  {
    title: '医院 DIP 运营监管平台',
    systems: [
      { title: '诊间辅助决策系统', url: import.meta.env.VITE_APP_DOCTOR_URL },
      { title: '病案首页质控系统', url: import.meta.env.VITE_APP_MEDICAL_QUALITY_URL },
      { title: '医保结算清单上报管理系统', url: import.meta.env.VITE_APP_DIP_SETTLE_URL },
      { title: 'DIP 运营监管系统', url: import.meta.env.VITE_APP_DIP_SUPERVISION_URL },
      { title: 'DRG 医疗质量评价系统', url: import.meta.env.VITE_APP_DRG_EVALUATE_URL }
    ]
  },
  {
    title: '医院 DRG 运营监管平台',
    systems: [
      { title: '诊间辅助决策系统', url: import.meta.env.VITE_APP_DOCTOR_URL },
      { title: '病案首页质控系统', url: import.meta.env.VITE_APP_MEDICAL_QUALITY_URL },
      { title: '医保结算清单上报管理系统', url: import.meta.env.VITE_APP_DRG_SETTLE_URL },
      { title: 'DRG 运营监管系统', url: import.meta.env.VITE_APP_DRG_SUPERVISION_URL },
      { title: 'DRG 医疗质量评价系统', url: import.meta.env.VITE_APP_DRG_EVALUATE_URL }
    ]
  },
  {
    title: '医保智能审核平台',
    systems: [
      { title: '医保智能审核', url: import.meta.env.VITE_APP_AUDIT_MONITOR },
      { title: '医保智能审核插件', url: import.meta.env.VITE_APP_AUDIT_UPDATER }
    ]
  },
  {
    title: 'OneCoding',
    systems: [
      { title: '诊间辅助决策系统', url: import.meta.env.VITE_APP_DOCTOR_URL },
      { title: '病案一体化系统', url: import.meta.env.VITE_APP_MEDICAL_INTEGRATION_URL },
      { title: '病案首页质控系统', url: import.meta.env.VITE_APP_MEDICAL_QUALITY_URL }
    ]
  }
]

const redirectToSystem = (system: any) => {
  window.open(system.url)
}
</script>
