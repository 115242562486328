<template>
  <ApplicationDarkMode>
    <div class="flex px-4 h-full">
      <NDropdown trigger="hover" :options="options" @select="handleSelect">
        <NButton text>
          <NAvatar class="!bg-white" round size="small">
            <svg t="1700124715991" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="28420" width="200" height="200">
              <path
                d="M512 512m-447.99999997 0a447.99999997 447.99999997 0 1 0 896 0 447.99999997 447.99999997 0 1 0-896 0Z"
                fill="#bff9f1"
                p-id="28421"
              ></path>
              <path
                d="M512 959.99999997c129.325 0 245.85000002-54.80000003 327.62499999-142.44300003-0.21699993-2.08399999-0.97199999-3.96500001-2.32799999-5.61300003-12.34900002-15.01200001-65.74799999-53.91599993-118.57900009-79.67899992-67.02299999-32.68900003-129.23199999-39.50000003-129.23199997-39.49999994l-92.97799995 266.9659999c5.14299999 0.17300005 10.30600001 0.269 15.49200001 0.26900002z"
                fill="#FFFFFF"
                p-id="28422"
              ></path>
              <path
                d="M512 959.99999997c2.451 0 4.897-0.02699995 7.33899998-0.06599998l-90.72-273.30000001s-62.20999999 6.81200001-129.23299997 39.5c-52.83099996 25.76299997-106.22999999 64.66700002-118.57900012 79.679-1.29499999 1.57400002-2.03500003 3.36300002-2.28899986 5.33600003C260.53699994 902.519 379.553 959.99999997 512 959.99999997z"
                fill="#FFFFFF"
                p-id="28423"
              ></path>
              <path d="M439.36700002 643.79399999l136.14099995 0 0 94.098L439.36700002 737.89199998z" fill="#F6CAA9" p-id="28424"></path>
              <path
                d="M686.454 449.885c0 110.79900002-79.55299996 200.61999996-177.68700009 200.61999997-98.138 0-177.69099999-89.821-177.69099992-200.61999997s79.55299996-200.61999996 177.69099992-200.62000002c98.13400012 0 177.68699996 89.821 177.68700009 200.62000002"
                fill="#F6CAA9"
                p-id="28425"
              ></path>
              <path
                d="M440.63900001 669.63800005s-15.26500012-3.41999998-11.63099997 19.44199995c4.00099999 25.14999998 79.23100009 236.921 79.23099996 236.92099988l79.59400004-226.83699981s7.26700002-30.24500004-11.17700005-27.81400019l-68.41699998 58.77900003-67.6-60.49099986z"
                fill="#88ddd1"
                p-id="28426"
              ></path>
              <path
                d="M535.76899995 847.68699994s-15.08299998-68.04999991-15.62799998-70.75099995c-0.54399999-2.701 4.9079999-10.26399981 4.90800012-14.58299998s-3.81899994-7.01999997-8.7230001-12.422c-4.9079999-5.40199998-8.72299995-16.022-8.72300002-16.02200003l0 0.51500008c-0.45400001 1.22999998-4.04600001 10.73-8.63299997 15.77600007-4.9079999 5.40199998-8.72299995 8.103-8.72300003 12.4219998 0 4.32300001 5.45300007 11.88200003 4.90800001 14.58300007-0.54399999 2.701-14.17600005 68.58999998-14.17599995 68.58999991l27.80299998 80.74600004-0.54400001-0.53899989 27.53100003-78.31500012z"
                fill="#3bae9e"
                p-id="28427"
              ></path>
              <path
                d="M353.53699993 461.64499996c0 16.56500001-12.221 29.99600004-27.29999993 29.99600004-15.07500001 0-27.296-13.43099999-27.296-29.99599994s12.221-29.99600004 27.296-29.99600006c15.07900007 0 27.29999995 13.43099999 27.29999993 29.99599996M718.21999993 461.27600004c0 16.56500001-12.221 29.99600004-27.29999992 29.99599995-15.07500001 0-27.296-13.43099999-27.296-29.99599995s12.221-29.99600004 27.296-29.99600007c15.07900007 0 27.29999995 13.43099999 27.29999992 29.99600007"
                fill="#F6CAA9"
                p-id="28428"
              ></path>
              <path
                d="M608.03299994 314.66200002s49.63399998 22.6209999 15.8830001 39.33699995c-30.773 8.85000001-94.306 2.94999999-124.08500007-0.98500003-29.77899994-3.931-112.66900006-31.46700009-112.66899998-31.46699994l2.48300002 20.16099995s62.73300001 22.5760001 115.14699998 29.99200003c52.11699999 7.37499999 110.19000004 14.75499999 135.00699996-3.93099995 21.839-13.77000001 15.88300001-37.37200004 15.88300005-37.37200002s27.296-2.94999999 30.27400003-8.85000001l-8.93400003-16.71999997c0.00100005 0-10.42099999 5.89999999-68.98900006 9.83499999"
                fill="#ECB485"
                p-id="28429"
              ></path>
              <path
                d="M619.614 235.495s82.06099999-36.71400009-19.85499999-53.76099991c-101.912-17.04699999-207.79599997 36.71400009-232.94699997 91.78600002 0 0-44.99799994-7.86599994-54.266 86.5439999 0 57.69600007 9.26800004 72.11999998 9.26800005 72.11999998s14.225-3.93500001 13.23499988 33.43700004c0 0 10.91799999 12.78099999 15.87900006-0.98500007 0 0 2.97799994-25.57099999-0.99000002-33.437 0 0 43.67800001-23.60200002 39.70600001-107.1949999 0 0 46.65599996 22.6209999 118.12899997 30.48699997 71.46899998 7.86599994 113.16299988 0.98499994 113.1629999 0.98499998s0 45.23700001 39.70600016 73.75900003l-1.98400005 32.45199996s0.99000003 6.88500009 7.93999997 6.88499999 7.944-10.82000005 7.94400002-10.82-0.99400006-23.60099999 14.88900003-26.55199998c0 0 18.861-70.808-4.96600005-125.88100007 0 0 32.761-12.78500005 18.86100006-43.27199998-13.89300001-30.48599999-83.71200001-26.55199999-83.71200003-26.55199996"
                fill="#666666"
                p-id="28430"
              ></path>
              <path
                d="M439.72999999 635.162s25.803 13.32099993 63.24100001 13.321c43.97499991 1.80199999 72.68600001-12.96100003 72.68600004-12.96100002l0 13.3209999s-48.33899999 25.92600003-70.14500008 25.92600013-66.14400003-30.605-66.14399994-30.60499997l0.362-9.00200004z"
                fill="#ECB485"
                p-id="28431"
              ></path>
              <path
                d="M696.64000003 858.653l-32.194-1e-8c-4.20899998 0-7.63499999-3.37900003-7.63500006-7.53199995 0-4.14799997 3.426-7.528 7.63500006-7.52800005l16.92799997 0 0-27.71500003c0-4.15199998 3.426-7.53200003 7.63500003-7.53199991s7.63099984 3.37900003 7.63099999 7.53199991l1e-8 42.77500004zM625.94499999 858.65199998l-32.19799999-1e-8 0-42.77099994c0-4.15600002 3.426-7.536 7.635-7.53600002s7.63099984 3.37900003 7.63100006 7.53600002l0 27.71099992 16.93199993 0c4.20899998 0 7.63099984 3.37900003 7.63100004 7.53200002 0 4.14900001-3.421 7.528-7.63100004 7.52800001z"
                fill="#88ddd1"
                p-id="28432"
              ></path>
              <path
                d="M690.17999999 321.69399997l-2.272-7.57399996-3.44000003-8.80099996s-17.86700001 5.40800003-76.43499987 9.34299996c0 0 49.63399998 22.6209999 15.88299995 39.33699996-1.019 0.29300002-2.08 0.568-3.16900002 0.82900001 0.78500003 5.73499999 2.01999999 13.54000005 3.67299997 20.32700002 5.95400007-1.79800003 11.15699996-4.20699995 15.37900004-7.38599998 21.839-13.77000001 15.88300001-37.37200004 15.88299997-37.37200001s31.52000007-2.80200004 34.49799999-8.70300004z"
                fill="#272727"
                p-id="28433"
              ></path>
              <path
                d="M371.68699999 847.31000002c27.27399994 0 49.46200002-21.89099991 49.46200008-48.79300001 0-22.78600001-15.915-41.967-37.35400007-47.30900001 0.065-0.48300001 0.11000001-0.97299996 0.10999999-1.47299999l0-54.30899994a465.33199997 465.33199997 0 0 0-22.57000004 6.35399995c-0.039 1.54800001-0.05999988 3.08100005-0.06000001 4.58100007l0 43.37399992c0 0.36200009 0.02100007 0.71999987 0.0550001 1.07399996-22.31799994 4.71000002-39.105 24.30699998-39.10500004 47.70900006 0 26.90100004 22.18700002 48.792 49.46199999 48.79199994z m-1e-8-74.04400004c14.113 0 25.59700013 11.32800002 25.597 25.25100003 0 13.922-11.48299987 25.25-25.597 25.24999999-14.113 0-25.59700013-11.32800002-25.59700005-25.24999999 0-13.92300004 11.48299987-25.251 25.59700005-25.25100003zM699.991 811.47099999l0-11.01199994c0-25.93899999-18.84-47.60799994-43.72200001-52.41900009l0-40.28699996a463.99000009 463.99000009 0 0 0-23.837-6.64400001l0 47.416c-23.76999994 5.64000015-41.49000005 26.77600002-41.49 51.93400006l0 11.01199994c0 5.97399997 4.92300002 10.83399994 10.9830001 10.834 6.056 0 10.98300001-4.86099988 10.98299988-10.834l0-11.01199995c0-17.50800006 14.43700002-31.75400004 32.18500005-31.75399994l0.747 0c17.74400002 0 32.185 14.24600001 32.18499997 31.75399995l0 11.01199994c0 5.97399997 4.92700013 10.83399994 10.98300005 10.834 6.05499987 0.00100004 10.98300001-4.85999997 10.98299996-10.834z"
                fill="#3bae9e"
                p-id="28434"
              ></path>
            </svg>
          </NAvatar>

          <pre class="text-white ml-4">{{ user.user?.user.nickName }}</pre>
        </NButton>
      </NDropdown>
    </div>

    <NModal preset="card" class="!w-1/3" v-model:show="modal.show" title="修改密码">
      <NForm ref="modalFormRef" require-mark-placement="left" label-width="auto" label-placement="left" :model="modal.form" :rules="modal.rules">
        <NFormItem label="旧密码" path="oldPass">
          <NInput type="password" show-password-on="click" v-model:value="modal.form.oldPass"></NInput>
        </NFormItem>
        <NFormItem label="新密码" path="newPass">
          <NInput type="password" show-password-on="click" v-model:value="modal.form.newPass"></NInput>
        </NFormItem>
      </NForm>
      <template #footer>
        <NSpace justify="end">
          <NButton @click="modal.show = false"> 取消 </NButton>
          <NButton @click="handleSave" type="primary"> 保存 </NButton>
        </NSpace>
      </template>
    </NModal>
  </ApplicationDarkMode>
</template>

<script setup lang="tsx">
import { useRouter } from 'vue-router'
import { NIcon, useMessage } from 'naive-ui'
import { EditOutlined, AlignLeftOutlined, AlignRightOutlined, FullscreenOutlined, FullscreenExitOutlined, FileTextOutlined } from '@vicons/antd'
import { MoonOutline, SunnyOutline, ExitOutline } from '@vicons/ionicons5'
import { encrypt } from 'peace-library'
import { useFullscreen } from '@vueuse/core'
import { useLayoutStore, useThemeStore, useUserStore } from '@/store'
import { logout, updatePass } from '../service/'
import { cloneDeep } from 'lodash-es'
import { removeUserInfo } from '@/utils'

const router = useRouter()

const layout = useLayoutStore()
const theme = useThemeStore()
const user = useUserStore()
const message = useMessage()
const { isFullscreen, toggle: toggleFullScreen } = useFullscreen()

const modalFormRef = ref()

const modal = ref({
  show: false,
  form: {
    oldPass: '',
    newPass: ''
  },
  rules: {
    oldPass: [{ required: true, message: '请输入旧密码' }],
    newPass: [{ required: true, message: '请输入新密码' }]
  }
})

const renderFileTextOutlinedIcon = () => (
  <NIcon>
    <FileTextOutlined></FileTextOutlined>
  </NIcon>
)

const renderEditOutlinedIcon = () => (
  <NIcon>
    <EditOutlined></EditOutlined>
  </NIcon>
)

const renderExitOutlineIcon = () => (
  <NIcon>
    <ExitOutline></ExitOutline>
  </NIcon>
)

const options = [
  { label: '清理缓存', key: '清理缓存', icon: renderFileTextOutlinedIcon },
  { type: 'divider', key: 'divider1' },
  { label: '修改密码', key: '修改密码', icon: renderEditOutlinedIcon },
  { label: '退出登录', key: '退出登录', icon: renderExitOutlineIcon }
]

const handleSelect = async (key: string) => {
  if (key === '清理缓存') {
    window.$dialog?.warning({
      title: '提示',
      content: '即将清理个人配置与用户信息，你需要重新登录。',
      positiveText: '确定',
      negativeText: '取消',
      onPositiveClick: () => {
        localStorage.clear()
        sessionStorage.clear()
        window.location.href = window.location.origin + window.location.pathname
      }
    })
  }

  if (key === '修改密码') {
    modal.value.show = true
  }

  if (key === '退出登录') {
    await logout()

    router.push({ name: 'login' }).then(() => {
      removeUserInfo()
      window.location.reload()
    })
  }
}

const handleSave = async () => {
  const validateMessages = await modalFormRef.value.validate()

  if (!validateMessages) {
    const params = cloneDeep(modal.value.form)
    params.oldPass = encrypt(params.oldPass)
    params.newPass = encrypt(params.newPass)
    updatePass(params).then(() => {
      modal.value.show = false
      message.success('修改成功')
    })
  }
}
</script>
