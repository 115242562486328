import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useLoadingStore = defineStore('loading', () => {
  const show = ref(false)
  const description = ref('')

  return {
    show,
    description
  }
})
